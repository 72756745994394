<ngx-spinner [fullScreen]="false" size="large" type="ball-beat" [name]="'todo_details'"></ngx-spinner>
<div style="color: white" *ngIf="todo">

    <div class="todo-property">
        <button class="fa-btn-primary" (click)="changeStatus($event, todo)" [disabled]="isLoading">Mark as done</button>
    </div>

    <div class="todo-property">
        <textarea id="todoTitle" [(ngModel)]="todo.title" style="width: 100%" maxlength="128" rows="1" (change)="save()"
            (keydown.control.enter)="save()"></textarea>
    </div>

    <div class="todo-property">
        <label for="todoNote" class="form-label fa-small-text">Note</label>
        <textarea id="todoNote" [(ngModel)]="todo.note" style="width: 100%" rows="4" (change)="save()"
            (keydown.control.enter)="save()"></textarea>
    </div>

    <div class="todo-property">
        <label for="todoAssignee" class="form-label fa-small-text">Assignee</label>
        <ng-select [items]="people$ | async" bindLabel="name" autofocus bindValue="id" [(ngModel)]="todo.assignee"
            (change)="selectAssignee($event)">
        </ng-select>
    </div>

    <div class="row">
        <div class="col-6 todo-property">
            <label for="todoDueDate" class="form-label fa-small-text">Due Date</label>

            <div class="due-date-buttons">
                <button class="btn btn-outline-primary" type="button" (click)="changeDueDate('today')">Today</button>
                <button class="btn btn-outline-primary" type="button"
                    (click)="changeDueDate('tomorrow')">Tomorrow</button>
                <button class="btn btn-outline-primary" type="button" (click)="changeDueDate('next_week')">Next
                    Week</button>
                <!-- <input id="todoDueDate" type="date" style="flex: 1" [(ngModel)]="todo.due_date" (change)="save()"> -->
                <input id="todoDueDate" type="date" [(ngModel)]="todo.due_date" (change)="save()">
            </div>

        </div>
        <div class="col-6 todo-property">
            <label for="todoDueDate" class="form-label fa-small-text">Priority</label>
            <div class='priority-buttons'>
                <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off"
                    (click)="onChangePriority('low')">
                <label class="btn btn-outline-primary" for="option1">low</label>

                <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off"
                    (click)="onChangePriority('default')">
                <label class="btn btn-outline-primary" for="option2">default</label>

                <input type="radio" class="btn-check" name="options" id="option3" autocomplete="off"
                    (click)="onChangePriority('high')">
                <label class="btn btn-outline-primary" for="option3">high</label>
            </div>


        </div>
    </div>

    <div class="row">
        <div class="col-6 todo-property">
            <label for="todoRepeat" class="form-label fa-small-text">Repeat</label>
            <select [(ngModel)]="todo.repeat" (ngModelChange)="onChangeRepeat($event)" style="width: 100%">
                <option *ngFor="let level of repeatOptions" [ngValue]="level.id">{{level.label}}</option>
            </select>
        </div>

        <div class="col-6 todo-property">
            <label for="todoRepeatNext" class="form-label fa-small-text">From Done date</label>
            <input style="width: 100%" type="checkbox" [checked]="!todo.repeat_from_due_date"
                (change)="onChangeRepeatSchedule(todo)" />
        </div>

        <div class="col-12 todo-property" *ngIf="todo.repeat === 'week-days'">
            <div *ngFor="let weekDaySchedule of weekDaysSchedule">
                <input type="checkbox" id="weekday" [checked]="weekDaySchedule.checked"
                    [(ngModel)]="weekDaySchedule.checked" (change)="onChangeRepeatCustomSchedule()" />
                <label for="weekday">{{weekDaySchedule.label}}</label>
            </div>
            <!-- <pre>{{weekDaysSchedule | json}}</pre> -->
        </div>

        <div class="col-12 todo-property" *ngIf="todo.repeat === 'month-days'">
            <div *ngFor="let monthDaySchedule of monthDaysSchedule">
                <input type="checkbox" id="monthday" [checked]="monthDaySchedule.checked"
                    [(ngModel)]="monthDaySchedule.checked" (change)="onChangeRepeatCustomSchedule()" />
                <label for="monthday">{{monthDaySchedule.label}}</label>
            </div>
            <!-- <pre>{{monthDaysSchedule | json}}</pre> -->
        </div>

    </div>

    <!-- <div class="todo-property">
        <label for="todoRememberDate" class="form-label fa-small-text">Remember me</label>
        <input id="todoRememberDate" type="datetime-local" [min]="today" [(ngModel)]="todo.remember" style="width: 100%"
            (change)="save()">
    </div> -->

    <div class="todo-property">
        <label for="todoList" class="form-label fa-small-text">List</label>
        <ng-select bindLabel="path_name" autofocus bindValue="id" [(ngModel)]="todo.list"
            (change)="onChangeList($event)">
            <ng-option *ngFor="let list of listService.possibleLists$.asObservable() | async" [value]="list.id"
                [disabled]="list.type != 1">
                {{list.path_name}}</ng-option>
        </ng-select>
    </div>



    <div class="todo-property">
        <label for="todoComment" class="form-label fa-small-text">Comments</label>
        <div *ngFor="let comment of todo.comments">
            <textarea id="todoComment" [(ngModel)]="comment.content" style="width: 100%" rows="2"
                [disabled]="true"></textarea>
            <div style="padding-bottom: 20px" class="d-flex justify-content-between">
                <small>{{comment.created_at | date: "yyyy-MM-dd"}} by {{comment.commenter_object.name}}</small>
                <small (click)="removeComment(comment.id)" style="color: red; cursor: pointer;"> delete </small>
            </div>
        </div>
        <!-- <textarea id="todoComment" [(ngModel)]="newComment" style="width: 100%" rows="1" -->
        <!-- (keydown.control.enter)="addComment()"></textarea> -->
        <!-- <div style="padding-bottom: 20px" class="d-flex justify-content-end"> -->
        <!-- <small (click)="addComment()" style="color: green; cursor: pointer;"> add </small> -->
        <!-- </div> -->

        <div class="input-group mb-3">
            <input type="text" class="form-control" id="todoComment" [(ngModel)]="newComment" placeholder="your comment"
                (keydown.control.enter)="addComment()">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="addComment()" type="button">Add</button>
            </div>
        </div>


    </div>

    <div class="details-footer">

        <button class="fa-btn-primary mb-1" (click)="shareTask()">Share Link</button>
        <button class="btn btn-outline-danger mb-1" (click)="deleteTodo()">Delete Todo</button>
        <small *ngIf="todo.author_object">Created at: {{todo.created_at | date: 'yyyy.MM.dd'}} by {{todo.author_object.name}}</small>
        <br>
        <small>Updated at: {{todo.created_at | date: 'yyyy.MM.dd'}}</small>
        <br>
        <p style="font-size: xx-small">uuid: {{todo.uuid}}</p>
        <br>
        <div (click)="navigateToPrevTodo()" class="fa-pointer mb-3">
            <a style="font-size: xx-small;color: white;" *ngIf="todo.repeat_from_todo_uuid"
                routerLink="/my/board/todos/{{todo.repeat_from_todo_uuid}}">prev todo:
                {{todo.repeat_from_todo_uuid}}</a>
        </div>
    </div>

    <!-- <pre>{{todo | json}}</pre> -->
</div>