<div class="m-2">

    <h5 class="modal-heading" translate>
        Sorry for the inconvenience
    </h5>
    <br>
    <div>
        <p translate>
            The service is currently not available...Please wait a few seconds and try to refresh the page. We-ll be
            back online shortly!
        </p>
        <br> <br>
        <div class="d-flex justify-content-between">
            <a class="fa-btn-primary text-center" 
            href="https://stats.uptimerobot.com/43xPOhzOrx"
                style="margin-right: 10px;">Status</a>
            <a class="fa-btn-primary text-center pull-right" routerLink="" (click)="forceReload()">Refresh</a>
        </div>

        <br> <br>
        <p>&mdash; The Facciobene Team </p>
    </div>
</div>