<ngx-spinner></ngx-spinner>
<div class="screen page-container">

    <!-- HEADER -->
    <div class="d-flex justify-content-between mb-5 mx-4 header-container">
        <div class="header-container--logo">
            <a class="navbar-brand" routerLink="/">
                <img src="/assets/logo-text.png" alt="facciobene logo">
            </a>
        </div>

    </div>

    <div class="wrapper fadeInDown">

        <!-- FORM -->
        <form #userLoginForm="ngForm">
            <div id="formContent" class="row input-container d-flex flex-column p"
                style="padding-left: 30px;padding-right: 30px;">
                <div class="fadeIn first" style="padding-bottom: 20px;padding-top: 20px;">
                    <img src="/assets/logo.png" id="icon" alt="User Icon" />
                </div>

                <!-- EMAIL -->
                <div class="col-12 p-0 mb-4 form-group">
                    <label for="user_email">Email</label>
                    <input type="email" id="user_email" class="form-control"
                        [ngClass]="{ '': !validateForm.user_email, 'outline-danger shakeXAxis': validateForm.user_email }"
                        [(ngModel)]="user.email" (ngModelChange)="validateForm.user_email = false" placeholder="E-mail"
                        name="user_email" required>

                </div>

                <!-- REGISTER -->
                <div class="col-12 p-0 mb-5">
                    <button class="fa-btn-primary" (click)="requestNewPassword()"
                        [disabled]="user.email.length==0">
                        <span *ngIf="!processingData">Request new password</span>
                        <div *ngIf="processingData" class="spinner-border text-white" style="height:20px;width:20px;"
                            role="status">
                            <span class="sr-only" translate>Waiting</span>
                        </div>
                    </button>

                    <br>
                    <br>
                    <br>

                    <button class="fa-btn-secondary btn-block" (click)="backToLanding()">
                        <span *ngIf="!processingData">Back to Login</span>

                    </button>

                </div>
            </div>
        </form>
    </div>
</div>