
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { List, ListType } from '../interfaces/list.interface';
import { UrlConstantsService } from './url-constants.service';

@Injectable({ providedIn: 'root' })
export class ListService {

  shallRefreshLists = new BehaviorSubject<boolean>(false);
  currentList = new BehaviorSubject<List>(null);
  currentListId = new BehaviorSubject<number>(null);

  possibleLists$ = new BehaviorSubject<List[]>([]);

  public setCurrentListIdTimeStamp = null;
  public setCurrentListTimeStamp = null;

  constructor(
    private http: HttpClient,
    private urls: UrlConstantsService,
  ) {
    this.possibleLists$.next(this.getCachedFlatLists());
  }

  setCurrentListId(listId: number): void {
    this.currentListId.next(listId);
    this.setCurrentListIdTimeStamp = Date.now();
    console.log(`setting current list ${listId} to timestamp: ${this.setCurrentListIdTimeStamp}`);
  }

  checkIfListAlreadyCachedAndSetCurrentItIfSo(listId: number): void {
    const lists = this.getCachedLists();
    lists.forEach(list => {
      if (list.id === listId) {
        this.cacheCurrentList(list);
      }
    });
  }

  cacheCurrentList(list: List): void {
    console.log(`caching current list ${list.id}`);
    this.currentList.next(list);
    this.setCurrentListTimeStamp = Date.now();
    localStorage.setItem('FACCIOBENE_CURRENT_LIST', JSON.stringify(list));
  }

  getCachedCurrentList(): List[] | null {
    const list = localStorage.getItem('FACCIOBENE_CURRENT_LIST');
    return JSON.parse(list);
  }

  getCachedLists(): List[] | null {
    const lists = localStorage.getItem('FACCIOBENE_LISTS');
    return JSON.parse(lists);
  }

  getLists(): Observable<List[]> {
    return this.http.get<List[]>(this.urls.MY_LISTS).pipe(tap((res) => {
      localStorage.setItem('FACCIOBENE_LISTS', JSON.stringify(res));
    }));
  }

  getFlatLists(): Observable<List[]> {
    return this.http.get<List[]>(this.urls.MY_FLAT_LISTS).pipe(tap((res) => {
      localStorage.setItem('FACCIOBENE_FLAT_LISTS', JSON.stringify(res));
      this.possibleLists$.next(res);
    }));
  }

  getCachedFlatLists(): List[] {
    const lists = localStorage.getItem('FACCIOBENE_FLAT_LISTS');
    if (!lists){
      console.warn('no cached flat lists');
    }

    return lists
      ? JSON.parse(lists)
      : [];
  }

  getCachedAllList(): List {
    const allLists = this.getCachedFlatLists().filter((list => list.type === ListType.ALL));
    if (!!allLists){
      return allLists[0];
    }
    return null;
  }

  getList(listId): Observable<List> {
    return this.http.get<List>(this.urls.MY_LISTS_BY_ID(listId));
  }

  getCachedListById(listId: number): List {
    return this.getCachedFlatLists().find(el => el.id = listId);
  }

  shareWithUser(listId, email, firstName, lastName): Observable<any> {
    return this.http.post<any>(this.urls.SHARE_LIST_BY_LIST_ID(listId), {
      email, first_name: firstName, last_name: lastName
    });
  }

  updateList(list): Observable<List> {
    return this.http.patch<List>(this.urls.MY_LISTS_BY_ID(list.id), list);
  }

  addList(list: List): Observable<any> {
    return this.http.post(this.urls.MY_LISTS, list);
  }

  createSubList(parent_list: List, child_list): Observable<any> {
    return this.http.post(this.urls.MY_SUB_LISTS, {
      parent_list, child_list
    });
  }

  deleteList(list: List): Observable<any> {
    return this.http.delete(this.urls.MY_LISTS_BY_ID(list.id));
  }
}
