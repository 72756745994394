import { Component, Input, OnInit } from '@angular/core';

import { environment } from '@facciobene-webapp/environments/environment';
import { versions } from '@shared/versions';
import { HealthStatusService } from '@facciobene-webapp/app/services/health-status.service';

@Component({
  selector: 'app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class AppVersionComponent implements OnInit {
  versions = versions;
  environment = environment;

  @Input()
  shortVersion = true;

  constructor(
    public healthStatusService: HealthStatusService,
  ) { }

  async ngOnInit() {
  }
}
