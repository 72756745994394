import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FACCIOBENE_ENVIRONMENT } from '@facciobene-webapp/app/injectors.constant';
import { IEnvironment } from '@facciobene-webapp/app/interfaces/environment.interface';

@Component({
  selector: 'pw-unhealthy-info',
  templateUrl: './unhealthy-info.component.html',
  styleUrls: ['./unhealthy-info.component.scss'],
})
export class UnhealthyInfoComponent implements OnInit {
  constructor(@Optional() @Inject(FACCIOBENE_ENVIRONMENT) public environment: IEnvironment) {}

  ngOnInit(): void {}

  forceReload() {
    if (this.environment.production) {
      console.log('hard reloading location');
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = location.href;
      document.body.appendChild(form);
      form.submit();
    } else {
      console.log('hard reloading location');
      window.location.reload();
    }
  }
}
