import { getIsoToday, getIsoTomorrow } from '../lib';
import { List, ListType } from '../modules/my-module/modules/board-module/interfaces/list.interface';
import { Todo } from '../modules/my-module/modules/board-module/interfaces/todo.interface';


export const getTodosByList = (list: List, todos: Todo[], withChildren = false, userId: number = null) => {
    if (!todos) {
        return todos;
    }

    if (list.type === ListType.DEFAULT) {
        let todosFromThisList = todos.filter((el) => el.list === list.id);
        let allTodos: Todo[] = [...todosFromThisList]
        if (withChildren) {
            list.children.forEach(children => {
                const childenTodos = getTodosByList(children, todos, true, userId)
                allTodos = [...allTodos, ...childenTodos];
            });
        }

        return allTodos;
    } else if (list.type === ListType.TODAY) {
        // include overdue todos
        return todos.filter((el) => el.due_date <= getIsoToday());
    } else if (list.type === ListType.TOMORROW) {
        return todos.filter((el) => el.due_date === getIsoTomorrow());
    } else if (list.type === ListType.OVERDUE) {
        return todos.filter((el) => el.due_date < getIsoToday());
    } else if (list.type === ListType.ASSIGNED_TO_ME) {
        return todos.filter(el => el.assignee === userId);
    } else if (list.type === ListType.UNPLANNED) {
        return todos.filter(el => !el.due_date);
    } else if (list.type === ListType.UNASSIGNED) {
        return todos.filter(el => !el.assignee);
    } else if (list.type === ListType.ALL) {
        return todos;
    }
};
