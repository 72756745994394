import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';
@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {

  processingData = false;
  validateForm = {
    user_email: null,
  };

  user = {
    email: '',
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private angularFireAnalytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    if (this.authService.isAuthenticatedUser()) {
      this.router.navigate(['/my/board']);
    }
  }

  backToLanding(): void {
    this.router.navigate(['/login']);
  }

  requestNewPassword(): void {
    console.log(this.user);
    this.processingData = true;

    this.authService
      .forgotPassword(this.user)
      .subscribe((res: any) => {
        this.processingData = false;
        if (res) {
          this.router.navigate(['/']);
        } else {
          console.log(res);
        }
      });
  }
}
