import { Inject, Injectable, Optional } from '@angular/core';
import { FACCIOBENE_ENVIRONMENT } from '@facciobene-webapp/app/injectors.constant';
import { IEnvironment } from '@facciobene-webapp/app/interfaces/environment.interface';

@Injectable({ providedIn: 'root' })
export class UrlConstantsService {
  get baseUrl(): string {
    return this.environment.baseUrl;
  }

  public MY_PROFILE = `${this.baseUrl}/api/v1/my-profile`;
  public MY_LISTS = `${this.baseUrl}/api/v1/items/lists`;
  public MY_FLAT_LISTS = `${this.baseUrl}/api/v1/items/flat-lists`;
  public MY_SUB_LISTS = `${this.baseUrl}/api/v1/items/sub-list`;
  public MY_LISTS_BY_ID = (id) => `${this.baseUrl}/api/v1/items/lists/${id}`;
  public MY_TODOS_BY_LIST_ID = (id) => `${this.baseUrl}/api/v1/items/lists/${id}/todos`;
  public SHARE_LIST_BY_LIST_ID = (id) => `${this.baseUrl}/api/v1/items/lists/${id}/share`;
  public MY_TODOS = `${this.baseUrl}/api/v1/items/todos`;
  public SYNC_ITEMS = `${this.baseUrl}/api/v1/items/sync`;
  public MY_TODOS_BY_ID = (id) => `${this.baseUrl}/api/v1/items/todos/${id}`;
  public MY_TODOS_BY_UUID = (id) => `${this.baseUrl}/api/v1/items/todos_by_uuid/${id}`;
  public MY_TODOS_BY_UI_UUID = (uuid) => `${this.baseUrl}/api/v1/items/todos_by_ui_uuid/${uuid}`;
  public POSSIBLE_ASSIGNEES_FOR_TODOS_BY_ID = (id) => `${this.baseUrl}/api/v1/items/todos/${id}/possible_assignees`;
  public CHANGE_CHECK_STATUS_FOR_TODOS_BY_ID = (id) => `${this.baseUrl}/api/v1/items/todos/${id}/check_status`;
  public MY_TODOS_COMMENTS = (id) => `${this.baseUrl}/api/v1/items/todos/${id}/comments`;
  public MY_TODOS_COMMENTS_BY_ID = (id) => `${this.baseUrl}/api/v1/items/comments/${id}`;
  public ACTIVITY = `${this.baseUrl}/api/v1/items/activity`;

  constructor(@Optional() @Inject(FACCIOBENE_ENVIRONMENT) public environment: IEnvironment) { }

}
