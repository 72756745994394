<div class="page-container p-1">
  <div class="d-flex justify-content-end">
    <button mat-icon-button class="fa-btn-without-background" (click)="close()">
      <mat-icon class="close-icon fa-white-icon">close</mat-icon>
    </button>
  </div>

  <app-todo-details *ngIf="selectedTodoUUID" [selectedTodoUUID]="selectedTodoUUID"
    (onFinishedLoading)="onFinishedLoading()" (closeModal)="close()"></app-todo-details>

  <app-todo-details *ngIf="selectedTodoUIUUID && !selectedTodoUUID" [selectedTodoUIUUID]="selectedTodoUIUUID"
    (onFinishedLoading)="onFinishedLoading()" (closeModal)="close()"></app-todo-details>

</div>