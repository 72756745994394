
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { Todo } from '../modules/my-module/modules/board-module/interfaces/todo.interface';
import { List } from '../modules/my-module/modules/board-module/interfaces/list.interface';

export interface IPublicTodoListResponse { list: List; todos: Todo[]; }


@Injectable({ providedIn: 'root' })
export class PublicTodoService {

  constructor(
    private http: HttpClient,
    private urls: UrlConstantsService,
  ) { }

  getTodosByListUUID(listUUID): Observable<IPublicTodoListResponse> {
    console.log('getTodosByListUUID: ' + listUUID);
    return this.http.get<IPublicTodoListResponse>(this.urls.PUBLIC_TODOS_BY_LIST_UUID(listUUID));
  }
}
