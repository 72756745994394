import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export interface IAuthTokens {
  access: string;
  refresh?: string;
  accepted_terms?: boolean;
}
export interface IDecodedAccessToken {
  exp: number;
  jti: string;
  name: string;
  token_type: string;
  user_id: number;
  platform_admin?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public decodedAccessToken$: Observable<IDecodedAccessToken>;
  private _accessToken = 'accessToken';
  private _refreshToken = 'refreshToken';
  private _decodedAccessToken$: BehaviorSubject<IDecodedAccessToken>;
  logout$ = new BehaviorSubject<boolean>(false);
  private _autoRefreshTokensTimeoutId: number | any;
  private _afterLoginPath = 'after-login-link';

  constructor(
    @Optional() private angularFireAnalytics: AngularFireAnalytics,
    private router: Router,
    private http: HttpClient,
    private _urls: UrlConstantsService,
    private _toastr: ToastrService,
    private _translate: TranslateService
  ) {
    this._decodedAccessToken$ = new BehaviorSubject<IDecodedAccessToken>(this._getDecodedAccessToken());
    this.decodedAccessToken$ = this._decodedAccessToken$.asObservable();
  }

  public clearAuthToken(): void {
    console.log('clearAuthToken');
    localStorage.removeItem(this._accessToken);
    localStorage.removeItem(this._refreshToken);
    this._decodedAccessToken$.next(this._getDecodedAccessToken(null));
  }

  public setAfterLoginPath(path: string): void {
    sessionStorage.setItem(this._afterLoginPath, path);
  }

  public logout(keepAfterLoginPath: boolean = false, afterLogoutPage: string = '/'): void {
    console.log(`going to log out '${keepAfterLoginPath}', '${afterLogoutPage}'`);
    try {
      this.logout$.next(true);
    } catch (error) {
      console.error(error);
    }
    if (this.isAuthenticatedUser()) {
      this.angularFireAnalytics?.logEvent('user_logout');
    }

    if (this.isAuthenticatedAdmin()) {
      this.angularFireAnalytics?.logEvent('admin_logout');
    }

    localStorage.clear();

    this.clearAuthToken();
    Sentry.configureScope((scope) => scope.setUser(null));
    this.angularFireAnalytics.setUserId(null);
    if (keepAfterLoginPath) { this.setAfterLoginPath(this.router.url); }
    // this.router.navigate([afterLogoutPage]);
  }

  public putTokenLocalStorage(token: string): void {
    window.localStorage.removeItem('token');
    window.localStorage.setItem('token', token);
  }

  public saveAuthToken(tokens: IAuthTokens): void {
    console.log('saving tokens');
    localStorage.setItem(this._accessToken, tokens.access);
    if (!!tokens.refresh) { localStorage.setItem(this._refreshToken, tokens.refresh); }
    this._decodedAccessToken$.next(this._getDecodedAccessToken(tokens.access));
    this._autoRefreshTokens();
  }

  public getUserId(): number {
    return this._getDecodedAccessToken()?.user_id;
  }

  public getUsername(): string {
    return this._getDecodedAccessToken()?.name;
  }

  public isAuthenticated(): boolean {
    if (this.isExpired()) {
      this.clearAuthToken();
      return false;
    }
    return !!this._getDecodedAccessToken()?.user_id;
  }

  public isAuthenticatedAdmin(): boolean {
    if (this.isExpired()) {
      this.clearAuthToken();
      return false;
    }
    return !!this._getDecodedAccessToken()?.platform_admin;
  }

  public isAuthenticatedUser(): boolean {
    if (this.isExpired()) {
      this.clearAuthToken();
      return false;
    }
    const result = this._getDecodedAccessToken() != null;
    return result;
  }

  public getExpiration(): number {
    return this._getDecodedAccessToken()?.exp || null;
  }

  public isExpired(): boolean {
    if (this._getDecodedAccessToken() && this._getDecodedAccessToken().exp) {
      const exp = this._getDecodedAccessToken().exp * 1000;
      const now = Date.now();
      if (exp - now > 0) {
        return false;
      }
      return true;
    }
    return true;
  }

  public refreshToken(): Observable<IAuthTokens> {
    console.log('refreshToken');
    return this.http
      .post<IAuthTokens>(
        this._urls.REFRESH_TOKENS
        , { refresh: this.getRefreshToken() })
      .pipe(tap((tokens) => this.saveAuthToken(tokens)));
  }

  private _autoRefreshTokens(): void {
    if (!this.isAuthenticated()) return;

    const expirationPeriod = this.getExpiration() * 1000 - Date.now();
    clearTimeout(this._autoRefreshTokensTimeoutId);
    this._autoRefreshTokensTimeoutId = setTimeout(() => this.refreshToken().subscribe(), expirationPeriod);
  }

  public getAccessToken(): string {
    return localStorage.getItem(this._accessToken);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this._refreshToken);
  }

  private _getDecodedAccessToken(accessToken: string = this.getAccessToken()): IDecodedAccessToken {
    try {
      return JSON.parse(atob(accessToken.split('.')[1]));
    } catch {
      return null;
    }
  }

  public loginUser(username: string, password: string): Observable<boolean> {
    this.angularFireAnalytics?.logEvent(`user_try_login`);
    return this.http
      .post<any>(this._urls.USER_LOGIN_EMAIL, { email: username, password })
      .pipe(
        tap(
          (tokens) => {
            Sentry.setUser({ email: username });
            this.saveAuthToken(tokens);

            this.angularFireAnalytics?.logEvent(`user_login`, {
            });

          },
          (error) => {
            console.warn(error);
            this._toastr.error(
              this._translate.instant('errors.could_not_log_in'),
              this._translate.instant('common.error')
            );
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

  public registerUser(formValue) {
    this.angularFireAnalytics?.logEvent(`user_try_register`);
    return this.http
      .post<any>(this._urls.USER_REGISTER_EMAIL, formValue)
      .pipe(
        tap(
          (tokens) => {
            Sentry.setUser({ email: formValue.email });
            this.saveAuthToken(tokens);

            this.angularFireAnalytics?.logEvent(`user_register`, {
            });

          },
          (error) => {
            console.warn(error);
            this._toastr.error(
              this._translate.instant('errors.could_not_log_in'),
              this._translate.instant('common.error')
            );
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

  public forgotPassword(formValue): Observable<boolean> {
    this.angularFireAnalytics?.logEvent(`user_try_forget_password`);
    return this.http
      .post<any>(this._urls.USER_FORGET_PASSWORD, formValue)
      .pipe(
        tap(
          (tokens) => {
            this.angularFireAnalytics?.logEvent(`user_forget_password`);
          },
          (error) => {
            console.warn(error);
            this._toastr.error(
              this._translate.instant('errors.could_not_log_in'),
              this._translate.instant('common.error')
            );
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

}
