import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const FACCIOBENE_AVAILABLE_LANGUAGES = new InjectionToken<string[]>('FACCIOBENE_AVAILABLE_LANGUAGES');
export const FACCIOBENE_DEFAULT_LANGUAGES = new InjectionToken<string>('FACCIOBENE_DEFAULT_LANGUAGES');

export interface IIpInfo {
  city: string;
  country: string;
  hostname: string;
  ip: string;
  loc: string;
  org: string;
  postal: string;
  region: string;
  timezone: string;
}

@Injectable({ providedIn: 'root' })
export class LocaleService {
  private ipInfo = new BehaviorSubject<IIpInfo>(null);

  public country = new BehaviorSubject<string>('ch');

  private _availableLanguages: string[] = [];

  public get availableLanguages(): string[] {
    return [...this._availableLanguages];
  }

  public set availableLanguages(value: string[]) {
    this._availableLanguages = [...value];
  }

  private _defaultLanguage: string;

  public get defaultLanguage(): string {
    return this._defaultLanguage;
  }

  public set defaultLanguage(value: string) {
    this._defaultLanguage = value || this._getBrowserLanguage() || this.availableLanguages[0];
  }

  public get currentLanguage(): string {
    return this._language.value;
  }

  public get currentLanguageUpper(): string {
    return this._language.value.toUpperCase();
  }
  private _language: BehaviorSubject<string>;
  private _localeKey = 'lang';

  constructor(
    private http: HttpClient,
    private _translate: TranslateService,
    @Optional() private _dateAdapter: DateAdapter<any>,
    @Optional() @Inject(FACCIOBENE_DEFAULT_LANGUAGES) defaultLanguage: string,
    @Optional() @Inject(FACCIOBENE_AVAILABLE_LANGUAGES) availableLanguages: string[]
  ) {
    this.availableLanguages = availableLanguages;
    this.defaultLanguage = defaultLanguage;
  }

  public init(): void {
    this._language = new BehaviorSubject<string>(localStorage.getItem(this._localeKey) || this.defaultLanguage);

    this._translate.setDefaultLang(this.defaultLanguage);

    // console.log(`default language: ${this.defaultLanguage}`)
    // console.log(`current language: ${this._language.value}`)
    this.listenLocale().subscribe((lang) => {
      this._translate.use(lang);
      this._dateAdapter?.setLocale(lang);
    });

    this._translate.use(this._language.value);

    this._translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.setLocale(params.lang)
    })

    this.getUsersLocation()
  }

  public listenLocale(): Observable<string> {
    return this._language.asObservable();
  }

  public setLocale(lang: string): void {
    this._language.next(lang);
    localStorage.setItem(this._localeKey, lang);
  }

  private _getBrowserLanguage(): string {
    return window.navigator.languages.find((lang) => this.availableLanguages.includes(lang));
  }

  setCountry(country: string) {
    this.country.next(country)
  }

  public async getUsersLocation() {
    const IPInfo = await this.http.get<IIpInfo>('https://ipinfo.io?token=5d1862dd765391').toPromise();
    // console.log('ipinfo')
    // console.log(IPInfo)
    this.ipInfo.next(IPInfo)
    if (IPInfo.country) {
      this.country.next(IPInfo.country.toLowerCase())
    }
    return IPInfo;
  }

}
