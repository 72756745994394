import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import * as Sentry from "@sentry/browser";

export class TranslationHandler implements MissingTranslationHandler {
    constructor(){

    }
    handle(params: MissingTranslationHandlerParams) {
        // try {
        //     const msg = `translation key ${params.key} not found`;
        //     console.error(msg)
        //     throw new Error(msg)
        // } catch (err) {
        //     Sentry.captureException(err);
        // }
        return params.key;
    }
}