import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';
import { Assignee, Todo } from '../../interfaces/todo.interface';
import { ListService } from '../../services/list.service';
import { TodoService } from '../../services/todo.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@facciobene-webapp/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { getIsoNextWeek, getIsoToday, getIsoTomorrow } from '@facciobene-webapp/app/lib';


@Component({
  selector: 'app-todo-details',
  templateUrl: './todo-details.component.html',
  styleUrls: ['./todo-details.component.scss']

})
export class TodoDetailsViewComponent implements OnInit, OnDestroy {

  isLoading = false;
  today = new Date();
  
  @Input() selectedTodoUUID: null;
  @Input() selectedTodoUIUUID: null;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFinishedLoading = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();

  todo: Todo = null;
  people$: Observable<Assignee[]>;

  newComment = '';

  repeatOptions = [
    { id: 'none', label: 'None' },
    { id: 'daily', label: 'Daily' },
    { id: 'weekly', label: 'Weekly' },
    { id: 'bi-weekly', label: 'Bi-weekly' },
    { id: 'monthly', label: 'Monthly' },
    { id: 'yearly', label: 'Yearly' },
    { id: 'bi-daily', label: 'Bi-daily' },
    { id: 'work-days', label: 'work-days' },
    { id: 'week-days', label: 'week-days' },
    // { id: 'month-days', label: 'month-days' },
  ];

  priorityOptions: { id: 'low' | 'default' | 'high', label: string }[] = [
    { id: 'low', label: 'low' },
    { id: 'default', label: 'default' },
    { id: 'high', label: 'high' },
  ];

  weekDaysSchedule = [
    {
      label: 'Mondays',
      value: 'mondays',
      checked: true
    },
    {
      label: 'Tuesdays',
      value: 'tuesdays',
      checked: false
    },
    {
      label: 'Wednesdays',
      value: 'wednesdays',
      checked: false
    },
    {
      label: 'Thursdays',
      value: 'thursdays',
      checked: false
    },
    {
      label: 'Fridays',
      value: 'fridays',
      checked: false
    },
    {
      label: 'Saturdays',
      value: 'saturdays',
      checked: false
    },
    {
      label: 'Sundays',
      value: 'sundays',
      checked: false
    },
  ];

  monthDaysSchedule = [
  ];

  constructor(
    public router: Router,
    private spinner: NgxSpinnerService,
    public authService: AuthService,
    private toastrService: ToastrService,
    private todoService: TodoService,
    public listService: ListService,
    private clipboard: Clipboard,
  ) {
    for (let index = 1; index <= 31; index++) {
      let checked = false;
      if (index === 1) {
        checked = true;
      }
      this.monthDaysSchedule.push({
        label: index,
        checked,
      });
    }
  }

  async ngOnInit(): Promise<void> {
    console.log('this.selectedTodoUUID');
    console.log(this.selectedTodoUUID);
    console.log('this.selectedTodoUIUUID');
    console.log(this.selectedTodoUIUUID);
    await this.refresh();
  }

  ngOnDestroy(): void {
    this.spinner.hide('todo_details');
  }

  async refresh(): Promise<void> {
    this.spinner.show('todo_details');
    let result = null;

    if (this.selectedTodoUUID) {
      result = this.todoService.getTodoByUUID(this.selectedTodoUUID);
    } else if (this.selectedTodoUIUUID){
      result = this.todoService.getTodoByUIUUID(this.selectedTodoUIUUID);
    }

    result.subscribe(
      (val) => {
        this.todo = val;
        if (this.todo.due_date) {
          this.todo.due_date = new Date(this.todo.due_date).toISOString().split('T')[0];
        }

        if (this.todo.remember) {
          console.log(this.todo.remember);
          // HACK: we should store the values with timezone and work with the current user time zone here -> convert those values
          this.todo.remember = new Date(this.todo.remember).toISOString().slice(0, 16);
          console.log(this.todo.remember);
        }

        if (this.todo.repeat === 'week-days' && this.todo.repeat_schedule && this.todo.repeat_schedule.week_days !== null) {
          this.weekDaysSchedule = this.todo.repeat_schedule.week_days;
        }
        if (this.todo.repeat === 'month-days' && this.todo.repeat_schedule && this.todo.repeat_schedule.month_days !== null) {
          this.monthDaysSchedule = this.todo.repeat_schedule.month_days;
        }
        this.spinner.hide('todo_details');
        this.onFinishedLoading.emit();
        this.people$ = this.todoService.getPossibleAssigneesV2(this.todo.list);
      }
    );
  }

  async changeStatus(event: Event, todo: Todo): Promise<void> {
    this.isLoading = true;
    todo.done = true;
    await this.todoService.changeCheckTodo(todo, true).toPromise();
    this.toastrService.success('done');
    this.closeModal.emit();
    this.isLoading = true;
  }

  async save(): Promise<void> {
    try {
      // console.log(this.todo);
      this.todo = await this.todoService.patchTodo(this.todo).toPromise();
      // console.log(this.todo);
      this.toastrService.success('saved');
    } catch (error) {
      this.toastrService.error(JSON.stringify(error), 'Error!');
    }
  }

  async onChangePriority(event): Promise<void> {
    this.todo.priority = event;
    this.save();
  }

  async onChangeRepeatSchedule(todo): Promise<void> {
    console.log(todo);
    console.log(todo.repeat_from_due_date);
    todo.repeat_from_due_date = !todo.repeat_from_due_date;
    console.log(todo.repeat_from_due_date);
    this.save();
  }

  changeDueDate(date: 'today' | 'tomorrow' | 'next_week'): void {

    if (date === 'today') {
      this.todo.due_date = getIsoToday();
    }

    if (date === 'tomorrow') {
      this.todo.due_date = getIsoTomorrow();
    }

    if (date === 'next_week') {
      this.todo.due_date = getIsoNextWeek();
    }

    this.save();
  }


  onChangeRepeatCustomSchedule(): void {
    if (!this.todo.repeat_schedule) {
      this.todo.repeat_schedule = {};
    }
    if (this.todo.repeat === 'week-days') {
      this.todo.repeat_schedule.week_days = this.weekDaysSchedule;
    }
    if (this.todo.repeat === 'month-days') {

      this.todo.repeat_schedule.month_days = this.monthDaysSchedule;
    }
    console.log(this.todo.repeat_schedule);
    this.save();
  }


  async addComment(): Promise<void> {
    await this.todoService.addComment(this.todo, this.newComment).toPromise();
    this.toastrService.success('comment added');
    this.newComment = '';
    this.refresh();
  }

  async removeComment(commentId: number): Promise<void> {
    await this.todoService.removeComment(commentId).toPromise();
    this.refresh();
  }

  onChangeList(listId): void {
    console.log('onChangeList');
    console.log(listId);
    console.log('onChangeList');
    this.todo.list = listId;
    this.save();
    this.people$ = this.todoService.getPossibleAssigneesV2(this.todo.list);
  }

  deleteTodo(): void {
    console.log('deleting todo');
    if (confirm('Are you sure to delete ' + this.todo.title)) {
      this.todoService.deleteTodo(this.todo).subscribe(result => {
        console.log('deleted');
        this.closeModal.emit();
        this.toastrService.info('todo deleted');
      });
    }
  }

  selectAssignee(event): void {
    console.log(event);
    this.todo.assignee = event.id;
    this.save();
  }

  onChangeRepeat(val): void {
    if (val) {
      if (!this.todo.due_date) {
        this.todo.due_date = new Date().toISOString().split('T')[0];
      }
    }
    console.log(val);
    console.log(typeof (val));
    this.save();
  }

  navigateToPrevTodo(): void {
    this.router.navigate(['/my/board/todos/' + this.todo.repeat_from_todo_uuid]);
  }

  shareTask(): void {
    console.log('sharing the task');
    const linkToTodo = environment.uiDomain + '/my/board/todos/' + this.selectedTodoUUID;
    if (navigator.share) {
      navigator
        .share({
          text: 'Facciobene Task',
          url: linkToTodo,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      console.log('no share available');
      this.clipboard.copy(linkToTodo);

      // this.copyMessage(linkToTodo)
      this.toastrService.success('link to todo text copied to clipboard')
    }
  }
}
