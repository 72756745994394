import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {

  processingData = false;
  validateForm = {
    user_invitation_code: null,
    user_email: null,
    user_password: null,
    user_first_name: null,
    user_last_name: null,
  };

  user = {
    invitation_code: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private angularFireAnalytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    if (this.authService.isAuthenticatedUser()) {
      this.router.navigate(['/my/board']);
    }
  }

  register(): void {
    console.log(this.user);

    this.processingData = true;

    this.authService
      .registerUser(this.user)
      .subscribe((res: any) => {
        this.processingData = false;
        if (res) {
          this.angularFireAnalytics.logEvent('user_register');
          this.router.navigate(['/my/board']);
        } else {
          this.angularFireAnalytics.logEvent('user_failed_register');
          console.log(res);
        }
      });
  }

  login(): void {
    this.router.navigate(['/login']);
  }

}
