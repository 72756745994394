import { NgModule } from '@angular/core';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';

@NgModule({
  declarations: [],
  imports: [RecaptchaModule, RecaptchaFormsModule],
  exports: [RecaptchaModule, RecaptchaFormsModule],
  providers: [
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LckWA4dAAAAANxanGb0VggjePboZABXcxtjj4TD' } as RecaptchaSettings },
  ],
})
export class CaptchaModule { }
