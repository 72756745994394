import { Assignee } from './todo.interface';

export enum ListType {
    DEFAULT = 1,
    TODAY = 2,
    TOMORROW = 3,
    ALL = 4,
    OVERDUE = 5,
    DONE = 6,
    ASSIGNED_TO_ME = 7,
    UNPLANNED = 8,
    UNASSIGNED = 9,
}

export class List {
    id?: number;
    parent?: number | null | undefined;
    can_be_deleted?: boolean;
    can_be_edited?: boolean;
    can_be_shared?: boolean;
    is_public?: boolean;
    title: string;
    path_name?: string;
    uuid?: string;
    // DEFAULT = 1
    // TODAY = 2
    // TOMORROW = 3
    // ALL = 4
    // OVERDUE = 5
    // DONE = 6
    // ASSIGNED_TO_ME = 7

    type?: ListType;
    todos?: number;
    collaborators?: Assignee[];
    children?: List[];
    owner_object?: {
        name: string
    };
}
