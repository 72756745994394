<div class="row px-3 todo-item">
    <div class="col-md-10 offset-md-1">

        <div class="main-box p-2 px-2">

            <div class="check-box">
                <input type="checkbox" [checked]="todoItem.done" (change)="changeStatus($event, todoItem)"
                    [disabled]="(todoItem.done && todoItem.repeat) || readOnly || todoItem.disabled">
            </div>

            <div class="content-with-menu">
                <div class="content ps-2" (click)="openDetails($event, todoItem)" >
                    <p style="margin-bottom: 0px; overflow-wrap: anywhere;" id="todo-title-content"
                        [innerHtml]="todoItem.formattedHtmlTitle">
                        {{todoItem.title}}
                    </p>
                    <div class="d-flex justify-content-end align-items-center" id="todo-meta-content">
                            <span class="todo-detail" *ngIf="todoItem.due_date === today">today</span>
                            <span class="todo-detail" *ngIf="todoItem.due_date === tomorrow">tomorrow</span>
                            <span
                                class="todo-detail" *ngIf="todoItem.due_date !== today && todoItem.due_date !== tomorrow && todoItem.due_date < today"
                                style="color: red">{{todoItem.due_date
                                | date:
                                "yyyy-MM-dd" }}</span>

                            <span
                                class="todo-detail" *ngIf="todoItem.due_date !== today && todoItem.due_date !== tomorrow && todoItem.due_date > today">{{todoItem.due_date
                                | date:
                                "yyyy-MM-dd"}}</span>

                            <span class="separator"> - </span>

                            <span class="todo-detail" *ngIf="todoItem.repeat && todoItem.repeat !== 'none'">{{todoItem.repeat}}</span>

                            <span class="separator"> - </span>

                            <span class="todo-detail" *ngIf="todoItem.priority == 'low'" style="color: rgba(63, 101, 126, 0.863)">{{todoItem.priority}} prio</span>

                            <span class="separator"> - </span>

                            <span class="todo-detail" *ngIf="todoItem.priority == 'high'" style="color: red;">{{todoItem.priority}}
                                prio</span>

                            <span class="separator"> - </span>

                            <span class="todo-detail" *ngIf="todoItem.assignee_object">{{todoItem.assignee_object.first_name}}
                                {{todoItem.assignee_object.last_name}}</span>

                            <span class="separator"> - </span>

                            <span class="todo-detail" *ngIf="todoItem.comments && todoItem.comments.length > 0">commented</span>
                    </div>
                </div>
                <button mat-icon-button class="menu-button ps-2" [matMenuTriggerFor]="menu" *ngIf="!readOnly"
                    [matMenuTriggerData]="{item: todoItem}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<mat-menu class="todo-menu" #menu="matMenu">
    <ng-template matMenuContent let-item="item">

        <button mat-menu-item (click)="postPoneBy(item, 'today')">Due today</button>
        <button mat-menu-item (click)="postPoneBy(item, 'day')">Postpone to tomorrow</button>
        <button mat-menu-item (click)="postPoneBy(item, 'week')">Postpone to next week</button>
        <button mat-menu-item (click)="postPoneBy(item, 'month')">Postpone to next month</button>

    </ng-template>


</mat-menu>