<ngx-spinner></ngx-spinner>
<div class="screen page-container">

    <!-- HEADER -->
    <div class="d-flex justify-content-between mb-5 mx-4 header-container">
        <div class="header-container--logo">
            <a class="navbar-brand" routerLink="/">
                <img src="/assets/logo-text.png" alt="facciobene logo">
            </a>
        </div>

    </div>

    <div class="wrapper fadeInDown">

        <!-- FORM -->
        <form #userLoginForm="ngForm">
            <div id="formContent" class="row input-container d-flex flex-column p"
                style="padding-left: 30px;padding-right: 30px;">
                <div class="fadeIn first" style="padding-bottom: 20px;padding-top: 20px;">
                    <img src="/assets/logo.png" id="icon" alt="User Icon" />
                </div>

                <!-- FIRST NAME -->
                <div class="col-12 p-0 mb-4 form-group">
                    <label for="user_first_name">First name</label>
                    <input type="text" id="user_first_name" class="form-control"
                        [ngClass]="{ '': !validateForm.user_first_name, 'outline-danger shakeXAxis': validateForm.user_first_name }"
                        [(ngModel)]="user.first_name" (ngModelChange)="validateForm.user_first_name = false"
                        placeholder="Hans" name="user_first_name" required>
                </div>


                <!-- LAST NAME -->
                <div class="col-12 p-0 mb-4 form-group">
                    <label for="user_last_name">Last Name</label>
                    <input type="text" id="user_last_name" class="form-control"
                        [ngClass]="{ '': !validateForm.user_last_name, 'outline-danger shakeXAxis': validateForm.user_last_name }"
                        [(ngModel)]="user.last_name" (ngModelChange)="validateForm.user_last_name = false"
                        placeholder="Muller" name="user_last_name" required>

                </div>

                <!-- EMAIL -->
                <div class="col-12 p-0 mb-4 form-group">
                    <label for="user_email">Email</label>
                    <input type="email" id="user_email" class="form-control"
                        [ngClass]="{ '': !validateForm.user_email, 'outline-danger shakeXAxis': validateForm.user_email }"
                        [(ngModel)]="user.email" (ngModelChange)="validateForm.user_email = false" placeholder="E-mail"
                        name="user_email" required>

                </div>
                <!-- PASSWORD -->
                <div class="col-12 p-0 mb-2 form-group">
                    <label for="user_password">Password</label>
                    <input type="password" id="user_password" class="form-control" current-password
                        [ngClass]="{ '': !validateForm.user_password, 'outline-danger shakeXAxis': validateForm.user_password }"
                        [(ngModel)]="user.password" (ngModelChange)="validateForm.user_password = false"
                        placeholder="Password" name="user_password" required>
                </div>

                <div class="col-12 p-0 mb-4 form-group">
                    <label for="user_invitation_code">Invitation Code</label>
                    <input type="text" id="user_invitation_code" class="form-control"
                        [ngClass]="{ '': !validateForm.user_invitation_code, 'outline-danger shakeXAxis': validateForm.user_invitation_code }"
                        [(ngModel)]="user.invitation_code" (ngModelChange)="validateForm.user_invitation_code = false"
                        placeholder="842yz449bb" name="user_invitation_code" required>
                </div>

                <!-- REGISTER -->
                <div class="col-12 p-0 mb-5">
                    <button class="fa-btn-primary fadeIn fourth" (click)="register()"
                        [disabled]="user.email.length==0 || user.password.length==0 ">
                        <span *ngIf="!processingData">Register</span>
                        <div *ngIf="processingData" class="spinner-border text-white" style="height:20px;width:20px;"
                            role="status">
                            <span class="sr-only" translate>Waiting</span>
                        </div>
                    </button>
                </div>

                <div class="col-12 p-0 mb-5">
                    <button class="fa-btn-secondary btn-block fadeIn fourth" (click)="login()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>