import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { UrlConstantsService } from './url-constants.service';
import { IUser } from '../interfaces/user.interface';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


export interface IUserPreference {
  id: number;
  user: number;
  make_sound_when_checked_todo: boolean;
  sync_regularly_in_background: boolean;
  sync_regularly_in_background_period: number;
  default_list_id_for_new_todos: number;
}

const FACCIOBENE_USER_PREFERENCES = 'FACCIOBENE_USER_PREFERENCES';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: IUser = null;
  public userPreference = new BehaviorSubject<IUserPreference>(null);

  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private urlService: UrlConstantsService
  ) { }

  initService(): void {
    this.loadCachedPreferences();
    this.getOwnUserInfo().subscribe();
    this.getUserPreference().subscribe();
  }

  loadCachedPreferences(): void {
    const cache = localStorage.getItem(FACCIOBENE_USER_PREFERENCES);
    if (!!cache) {
      this.userPreference.next(JSON.parse(cache));
    }
  }

  cacheUserPreferences(userPreference): void {
    const cache = JSON.stringify(userPreference);
    localStorage.setItem(FACCIOBENE_USER_PREFERENCES, cache);
  }

  getUserPreference(): Observable<IUserPreference> {
    return this.http
      .get<IUserPreference>(this.urlService.USER_PREFERENCE)
      .pipe(
        tap(
          (response) => {
            this.userPreference.next(response);
            this.cacheUserPreferences(response);
          },
          (error) => {
            console.error(error);
            this.toastr.error(
              'Could not fetch user preferences',
              'Error'
            );
          }
        ),
        map((response) => response),
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      );
  }

  updateUserPreference(data: IUserPreference): Observable<IUserPreference> {
    return this.http.patch<IUserPreference>(this.urlService.USER_PREFERENCE, data);
  }

  uploadFeedback(feedback: string): Observable<any> {
    return this.http.post(this.urlService.USER_FEEDBACK, { feedback });
  }

  public getOwnUserInfo(): Observable<IUser> {
    return this.http.get<IUser>(this.urlService.USERS_SELF);
  }

  public updateOwnUserInfo(item: IUser): Observable<IUser> {
    return this.http.patch<IUser>(this.urlService.USERS_SELF, item);
  }
}
