// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';

// third-party-modules
import { ToastrModule } from 'ngx-toastr';
import {
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as Sentry from '@sentry/angular';

// components
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { UserLoginComponent } from './pages/user-login/user-login.component';
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { UserForgotPasswordComponent } from './pages/user-forgot-password/user-forgot-password.component';
import { PublicListComponent } from './pages/public-list/public-list.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AppComponent } from './app.component';

// interceptors
import { ErrorInterceptor } from './interceptors/error/error.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token/refresh-token.interceptor';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { FACCIOBENE_ENVIRONMENT } from './injectors.constant';

// own modules
import { CaptchaModule } from './modules/captcha/captcha.module';
import { SharedModule } from './modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

// services
import {
  FACCIOBENE_AVAILABLE_LANGUAGES,
  FACCIOBENE_DEFAULT_LANGUAGES,
  LocaleService,
} from './services/locale/locale.service';
import { TranslationHandler } from './services/translation-handler';
import { HealthStatusService } from './services/health-status.service';
import { environment } from '@facciobene-webapp/environments/environment';



@NgModule({
  declarations: [
    // components
    AppComponent,
    // pages
    LandingComponent,
    UserLoginComponent,
    UserRegisterComponent,
    UserForgotPasswordComponent,
    PublicListComponent,
    // Layouts
    PublicLayoutComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    NgxSpinnerModule,

    AngularFireAnalyticsModule,
    AngularFireRemoteConfigModule,
    AngularFireModule.initializeApp(environment.firebase),

    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TranslationHandler,
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:10000',
    }),
    SharedModule,
    AppRoutingModule,
    CaptchaModule,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LocaleService,
    ScreenTrackingService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: FACCIOBENE_DEFAULT_LANGUAGES,
      useValue: environment.defaultLanguage,
    },
    { provide: FACCIOBENE_AVAILABLE_LANGUAGES, useValue: environment.languages },
    { provide: FACCIOBENE_ENVIRONMENT, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private healthStatusService: HealthStatusService,
    private locale: LocaleService,
  ) {
    this.healthStatusService.init();
    this.locale.init();
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
