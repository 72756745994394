import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';
import { UrlConstantsService } from '@facciobene-webapp/app/services/url-constants.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private urls: UrlConstantsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.isAuthenticated()) { return next.handle(request); }
    if (request.url.includes(this.urls.HEALTH_STATUS) || request.headers.has('Authorization')) {
      return next.handle(request);
    }

    const authClone = request.clone({ setHeaders: { Authorization: `Bearer ${this.authService.getAccessToken()}` } });
    return next.handle(authClone);
  }
}
