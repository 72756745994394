import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  processingData = false;
  validateForm = {
    user_email: null,
    user_password: null
  };

  user = {
    email: '',
    password: '',
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private angularFireAnalytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    if (this.authService.isAuthenticatedUser()) {
      this.router.navigate(['/my/board']);
    }
  }

  register(): void {
    this.router.navigate(['/register']);
  }

  loginUser(userForm: NgForm, isValid = false): void {
    this.processingData = true;

    const email = userForm.value['user_email'];
    const password = userForm.value['user_password'];

    this.authService
      .loginUser(email, password)
      .subscribe((res: any) => {
        this.processingData = false;
        if (res) {
          this.angularFireAnalytics.logEvent('user_login');
          this.router.navigate(['/my/board']);
        } else {
          this.angularFireAnalytics.logEvent('user_failed_login');
          console.log(res);
          this.user.email = '';
          this.user.password = '';
          this.validateForm.user_email = null;
        }
      });
  }

  forgetPassword(): void {
    this.router.navigate(['/forgot-password']);
  }
}
