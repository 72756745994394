<div class="container-fluid main-container">
  <div class="version-wrapper text-center">
    <p *ngIf="shortVersion" class="text-center">
      v_{{versions?.hash}}_{{environment?.envName}} - {{ versions?.date | dateHuman }}
      <!-- v_{{versions?.hash}}_{{environment?.envName}} -->
    </p>
    <div *ngIf="!shortVersion">

      <div class="version-source">app:</div>
      <div>{{ versions?.date | dateHuman }}</div>
      <div>
        <p>{{versions?.hash}}</p>
      </div>
      <div>{{environment?.envName}}</div>
      <div class="version-source">api:</div>
      <div>{{ healthStatusService.healthInfo?.build_date}}</div>
      <div>
        <p>{{healthStatusService.healthInfo?.build_hash}}</p>
      </div>
      <div>{{healthStatusService.healthInfo?.build_env}}</div>
    </div>
  </div>
</div>