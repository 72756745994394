// angular
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

// third-party
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

// components
import { SuccessAnimationComponent } from './components/success-animation/success-animation.component';
import { UnhealthyInfoComponent } from './modals/unhealthy-info/unhealthy-info.component';
import { AppVersionComponent } from './components/app-version/app-version.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { TodoItemComponent } from './components/todo-item/todo-item.component';

// pipes
import { DateHumanPipe } from './pipes/date-human.pipe';
import { FilterAssigneePipe } from './pipes/filter-assignee.pipe';
import { FilterListPipe } from './pipes/filter-list.pipe';

const components = [
  TodoItemComponent,
  UnhealthyInfoComponent,
  ConfirmDialogComponent,
  SuccessAnimationComponent,
  AppVersionComponent,
];

const pipes = [
  DateHumanPipe,
  FilterAssigneePipe,
  FilterListPipe
];


@NgModule({
  declarations: [
    ...components,
    ...pipes
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    FontAwesomeModule,
    ButtonsModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
  ],
  exports: [
    ...pipes,
    UnhealthyInfoComponent,
    AppVersionComponent,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    ConfirmDialogComponent,
    SuccessAnimationComponent,
    TodoItemComponent,
  ]
})
export class SharedModule {
}
