import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from './url-constants.service';
import { BehaviorSubject, distinctUntilChanged, filter, interval, map, Observable, takeUntil } from 'rxjs';
import { UnhealthyInfoComponent } from '../modules/shared/modals/unhealthy-info/unhealthy-info.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

export interface IHealthStatus {
    build_date: Date;
    build_env: string;
    build_hash: string;
    status: string;
}


@Injectable({
    providedIn: 'root'
})
export class HealthStatusService {
    private _healthStatus$: BehaviorSubject<IHealthStatus> = new BehaviorSubject<IHealthStatus>(undefined);
    private _unhealthyInfoModalRef: MatDialogRef<UnhealthyInfoComponent> = null;

    public get healthInfo(): IHealthStatus {
        return this._healthStatus$.value;
    }

    public get isHealthy$(): Observable<boolean> {
        return this._healthStatus$.pipe(
            map((state) => state !== null),
            distinctUntilChanged()
        );
    }

    constructor(
        private _http: HttpClient,
        private _urlService: UrlConstantsService,
        private _urls: UrlConstantsService,
        private _dialog: MatDialog) {
    }

    async init() {
        const result = await this.getHealthStatus().toPromise()
        this._healthStatus$.next(result)

        this._manageUnhealthyModalSate();
        setTimeout(() => {
            this._getApiHealthStatus();
        }, 100)
    }

    public checkIfHealthy() {
        this._getApiHealthStatus();
    }

    public markAsUnhealthy(): void {
        console.log('markAsUnhealthy')
        this._healthStatus$.next(null);
        this._manageUnhealthyModalSate();
    }

    public getHealthStatus() {
        return this._http.get<IHealthStatus>(
            this._urlService.HEALTH_STATUS
        );
    }

    private _manageUnhealthyModalSate(): void {
        // console.log('_manageUnhealthyModalSate')
        this._healthStatus$.subscribe((isHealthy) => {
            if (isHealthy) {
                // console.log('_manageUnhealthyModalSate true')
                this._hideUnhealthyInfo()
            } else {
                // console.log('_manageUnhealthyModalSate false')
                this._showUnhealthyInfo()
            }
        });
    }

    private _showUnhealthyInfo(): void {
        if (this._unhealthyInfoModalRef) { return }
        this._unhealthyInfoModalRef = this._dialog.open(UnhealthyInfoComponent, { disableClose: true });
        console.log('going to show _showUnhealthyInfo')
        interval(1000)
            .pipe(takeUntil(this.isHealthy$.pipe(filter((isHealthy) => isHealthy))))
            .subscribe(() => {
                console.log('rechecking health status');
                this._getApiHealthStatus();
            });
    }

    private
    _hideUnhealthyInfo(): void {
        this._unhealthyInfoModalRef?.close();
        this._unhealthyInfoModalRef = null;
    }

    private _getApiHealthStatus(): void {
        this._http
            .get<IHealthStatus & { build_date: string }>(this._urls.HEALTH_STATUS)
            .pipe(map((data) => ({ ...data })))
            .subscribe(
                (status) => {
                    this._healthStatus$.next(status);
                },
                (error) => {
                    console.error(error);
                    this.markAsUnhealthy();
                }
            );
    }
}
