import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-todo-details-full-view',
  templateUrl: './todo-details-full-view.component.html',
  styleUrls: ['./todo-details-full-view.component.scss']
})
export class TodoDetailsFullViewComponent implements OnInit, OnDestroy {

  isLoading = true;

  selectedTodoUUID = null;
  selectedTodoUIUUID = null;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,

    @Optional() public dialogRef: MatDialogRef<TodoDetailsFullViewComponent>
  ) {
    console.log(this.data);
    if (this.data && this.data.todoUUID) {
      this.selectedTodoUUID = this.data.todoUUID;
    } else {
      if (this.data && this.data.todoUIUUID) {
        this.selectedTodoUIUUID = this.data.todoUIUUID;
      }
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onFinishedLoading(): void {
    this.isLoading = false;
  }

  close(): void {
    console.log('closing modal');
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.router.navigate(['/my/board']);
    }
  }
}
