<ngx-spinner></ngx-spinner>
<div class="screen page-container">

    <!-- HEADER -->
    <div class="d-flex justify-content-between mb-5 mx-4 header-container">
        <div class="header-container--logo">
            <a class="navbar-brand" routerLink="/">
                <img src="/assets/logo-text.png" alt="facciobene logo">
            </a>
        </div>

    </div>

    <div class="wrapper fadeInDown">

        <!-- FORM -->
        <form #userLoginForm="ngForm">
            <div id="formContent" class="row input-container d-flex flex-column p"
                style="padding-left: 30px;padding-right: 30px;">
                
                
                <div class="fadeIn first " style="padding-bottom: 20px;padding-top: 20px;">
                    <img src="/assets/logo.png" id="icon" alt="User Icon" class="modal-logo" />
                </div>


                <!-- EMAIL -->
                <div class="fadeIn second" class="col-12 p-0 mb-4 form-group">
                    <label for="user_email" translate>Email</label>
                    <input type="email" id="user_email" class="form-control"
                        [ngClass]="{ '': !validateForm.user_email, 'outline-danger shakeXAxis': validateForm.user_email }"
                        [(ngModel)]="user.email" (ngModelChange)="validateForm.user_email = false" placeholder="E-mail"
                        name="user_email" required>

                </div>
                <!-- PASSWORD -->
                <div class="col-12 p-0 mb-2 form-group">
                    <label for="user_password" translate>Password</label>
                    <input type="password" id="user_password" class="form-control" current-password
                        [ngClass]="{ '': !validateForm.user_password, 'outline-danger shakeXAxis': validateForm.user_password }"
                        [(ngModel)]="user.password" (ngModelChange)="validateForm.user_password = false"
                        placeholder="Password" name="user_password" required>

                </div>

                <!-- LOGIN -->
                <div class="col-12 p-0 mt-5 mb-5">
                    <button class="fa-btn-primary fadeIn fourth" (click)="loginUser(userLoginForm)"
                        [disabled]="user.email.length==0 || user.password.length==0" *ngIf="!processingData">
                        Login
                    </button>
                    <button class="fa-btn-primary fadeIn fourth"
                        [disabled]="user.email.length==0 || user.password.length==0" *ngIf="processingData">
                        <div *ngIf="processingData" class="spinner-border text-white" style="height:20px;width:20px;"
                            role="status">
                            <span class="sr-only" translate>Waiting</span>
                        </div>
                    </button>
                </div>

                <div class="col-12 p-0 mb-5">
                    <button class="fa-btn-secondary fadeIn fourth" (click)="register()">
                        <span>Register</span>
                    </button>
                </div>

                <div class="col-12 p-0 mb-5">
                    <button class="btn btn-link btn-block fadeIn fourth" (click)="forgetPassword()">
                        <span>Forgot password?</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>