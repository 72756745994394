import { Pipe, PipeTransform } from '@angular/core';
import { Todo } from '../../my-module/modules/board-module/interfaces/todo.interface';
import { UiService } from '../../my-module/modules/board-module/services/ui.service';

@Pipe({
  name: 'filterAssignee',
  pure: false
})
export class FilterAssigneePipe implements PipeTransform {

  constructor(private uiService: UiService) {
  }

  transform(items: Todo[]): any {
    // console.log('filtering todos');
    if (!items) {
      return items;
    }

    if (this.uiService.filteredAssignee.getValue() === null) {
      return items;
    }

    console.log(`filtering todos by assignee: ${this.uiService.filteredAssignee.getValue().id}`);

    return items.filter((e) => {
      return e.assignee === this.uiService.filteredAssignee.getValue().id;
    });
  }
}
