import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Layouts
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { UserLoginComponent } from './pages/user-login/user-login.component';

// Pages
import { LandingComponent } from './pages/landing/landing.component';
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { UserForgotPasswordComponent } from './pages/user-forgot-password/user-forgot-password.component';
import { PublicListComponent } from './pages/public-list/public-list.component';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: '', redirectTo: 'landing', pathMatch: 'full' },
      { path: 'landing', component: LandingComponent, pathMatch: 'full' },
      { path: 'register', component: UserRegisterComponent, pathMatch: 'full' },
      { path: 'forgot-password', component: UserForgotPasswordComponent, pathMatch: 'full' },
      { path: 'login', component: UserLoginComponent, pathMatch: 'full' },
    ]
  },
  { path: 'public/lists/:uuid', component: PublicListComponent, pathMatch: 'full' },
  // http://localhost:4200/public/lists/22f30930-5e5f-4ef7-9591-4b2efca0aa0d
  {
    path: 'my',
    loadChildren: () => import('./modules/my-module/my.module').then((m) => m.MyModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {scrollPositionRestoration: 'enabled'}
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
