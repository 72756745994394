export const stringToBoolean = (s: string) => {
  switch (s.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;

    case 'false':
    case 'no':
    case '0':
    case null:
      return false;

    default:
      return Boolean(s);
  }
};


export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};


export const getIsoToday = () => {
  return new Date().toISOString().split('T')[0];
};

export const getIsoTomorrow = () => {
  const date = new Date();

  date.setDate(date.getDate() + 1);

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  const tomorrow = formatDate(date);
  return tomorrow;
};


export const getIsoNextWeek = () => {
  const date = new Date();

  date.setDate(date.getDate() + 7);

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  const tomorrow = formatDate(date);
  return tomorrow;
};