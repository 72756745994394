import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OnlineService } from '@facciobene-webapp/app/modules/my-module/modules/board-module/services/online.service';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';
import { HealthStatusService } from '@facciobene-webapp/app/services/health-status.service';
import { UrlConstantsService } from '@facciobene-webapp/app/services/url-constants.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private onlineService: OnlineService,
    private authService: AuthService,
    private _urls: UrlConstantsService,
    private _toastrService: ToastrService,
    @Optional() private _healthCheck: HealthStatusService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (request.url.includes('login')) {
          console.warn('could not log in user with email and password');
          this.authService.logout(true, window.location.pathname);
          return throwError(err?.error);
        }

        // auto logout and handle error if 401 response returned from API
        if (err.status === 401) {
          this.authService.logout(true);
          console.warn('received a 401');
          if (err?.details) {
            console.warn(err.details);
          }
          if (err?.code) {
            console.warn(err.code);
          }
          return throwError(err?.error);
        }

        if (err.status === 403) {
          this.authService.logout(true);
          console.warn('received a 403');
          if (err?.details) {
            console.warn(err.details);
          }
          if (err?.code) {
            console.warn(err.code);
          }
          return throwError(err?.error);
        }



        if (err.status === 500) {
          console.error('internal server error');
          this._toastrService.error(
            'Ups, the server had an internal error, please try again later',
            'Error',
            { closeButton: true }
          );
        }

        if (err.status === 502 || err.status === 504) {
          if (this.onlineService.onlineMode.value){
            console.error('timeout');
            if (request.url !== this._urls.HEALTH_STATUS) {
              this._healthCheck?.checkIfHealthy();
            }
            return throwError(err?.error);
          } else {
            this._toastrService.error(
              'It seems like you are offline, pls get back online and try again',
              'Error',
              { closeButton: true }
            );
          }
        }

        if (this.onlineService.onlineMode.value){
          return throwError(err?.error);
        } else {
          this._toastrService.error(
            'It seems like you are offline, pls get back online and try again',
            'Error',
            { closeButton: true }
          );
          return throwError(err?.error);
        }

        // err.error will give the details payload from the server
        return throwError(err?.error);
      })
    );
  }
}
