import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';
import { HealthStatusService } from '@facciobene-webapp/app/services/health-status.service';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {

  constructor(
    private healthStatusService: HealthStatusService,
    private _authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (!this._authService.isAuthenticatedUser()) {
      this.router.navigate(['/login']);
    } else {
      console.log('already logged in');
      this.router.navigate(['/my']);
    }

    this.healthStatusService.getHealthStatus().subscribe(result => {
      console.log(result);
    }, (error) => {
      console.error(error);
    });
  }
}
