import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateHuman',
})
export class DateHumanPipe implements PipeTransform {

  constructor(private _translateService: TranslateService) { }

  transform(time: any, onlyDays?: boolean): any {
    if (time) {
      switch (typeof time) {
        case 'number':
          break;
        case 'string':
          time = +new Date(time);
          break;
        case 'object':
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }

      let time_formats = [
        [60, this._translateService.instant('common.seconds'), 1], // 60
        [120, this._translateService.instant('common.minute_ago'), this._translateService.instant('common.minute_ago')], // 60*2
        [3600, 'minutes', 60], // 60*60, 60
        [7200, this._translateService.instant('common.hour_ago'), this._translateService.instant('common.hour_ago')], // 60*60*2
        [86400, this._translateService.instant('common.hours'), 3600], // 60*60*24, 60*60
        [172800, this._translateService.instant('common.yesterday'), this._translateService.instant('common.tomorrow')], // 60*60*24*2
        [604800, 'days', 86400], // 60*60*24*7, 60*60*24
        [1209600, this._translateService.instant('common.last_week'), this._translateService.instant('common.next_week')], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, this._translateService.instant('common.last_month'), this._translateService.instant('common.next_month')], // 60*60*24*7*4*2
        [29030400, this._translateService.instant('common.months'), 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, this._translateService.instant('common.last_year'), this._translateService.instant('common.next_year')], // 60*60*24*7*4*12*2
        [2903040000, this._translateService.instant('common.years'), 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      ];

      if (onlyDays) {
        time_formats = [
          [86400, 'today', 3600], // 60*60*24, 60*60
          [172800, 'yesterday', 'tomorrow'], // 60*60*24*2
          [604800, 'days', 86400], // 60*60*24*7, 60*60*24
          [1209600, this._translateService.instant('common.last_week'), this._translateService.instant('common.next_week')], // 60*60*24*7*4*2
          [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
          [4838400, this._translateService.instant('common.last_month'), this._translateService.instant('common.next_month')], // 60*60*24*7*4*2
          [29030400, this._translateService.instant('common.months'), 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
          [58060800, this._translateService.instant('common.last_year'), this._translateService.instant('common.next_year')], // 60*60*24*7*4*12*2
          [2903040000, this._translateService.instant('common.years'), 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        ];
      }


      let seconds = (+new Date() - time) / 1000;
      let token = 'ago';
      let listChoice = 1;
      if (Math.abs(seconds) <= 2) {
        return this._translateService.instant('common.just_now');
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = this._translateService.instant('common.from_now');
        listChoice = 2;
      }
      let i = 0;
      let format;
      while (format = time_formats[i++]) {
        if (seconds < format[0]) {
          if (typeof format[2] === 'string') {
            return format[listChoice];
          } else {
            return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
          }
        }
      }
      return time;
    }
  }
}

