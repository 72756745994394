
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, concat, EMPTY, map, Observable, of, retry, share, throwError, timeout, TimeoutError } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { Assignee, Todo, TodoComment } from '../interfaces/todo.interface';
import { tap } from 'rxjs';
import { ListService } from './list.service';
import { OnlineService } from './online.service';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';
import { List, ListType } from '../interfaces/list.interface';
import { UserService } from '@facciobene-webapp/app/services/user.service';
import { IListResponse } from '@facciobene-webapp/app/modules/shared/components/list-loader/list-loader.component';
import { getTodosByList } from '@facciobene-webapp/app/utils/list-utils';
import { getIsoToday, getIsoTomorrow } from '@facciobene-webapp/app/lib';
import { UiService } from './ui.service';

export class SyncTask<T extends Todo> {
  constructor(
    public url: string,
    public body: T,
    public params?: string) { }
}

const HTTP_TIMEOUT_IN_MS = 5000;
const FACCIOBENE_TODO_ITEM_TASKS = 'FACCIOBENE_TODO_ITEM_TASKS';
const FACCIOBENE_TODO_ITEM_ITEMS = 'FACCIOBENE_TODO_ITEM_ITEMS';

@Injectable({ providedIn: 'root' })
export class TodoService {

  // TODO: split sync utils from todo manage utils;

  private syncedTodoItems = new BehaviorSubject<Todo[]>([]);
  public readonly syncedTodoItems$ = this.syncedTodoItems.asObservable();

  private initialized = false;
  private intervalId = null;
  private intervalForAddNewTasksId = null;

  public lastTrySyncTimeStamp = new BehaviorSubject<Date>(null);
  public lastSuccessSyncTimeStamp = new BehaviorSubject<Date>(null);
  public nextSyncTimeStamp = new BehaviorSubject<Date>(null);
  public readonly SYNC_INTERVAL = 5_000;

  constructor(
    private listService: ListService,
    private userService: UserService,
    private http: HttpClient,
    private urls: UrlConstantsService,
    private authService: AuthService,
    private onlineService: OnlineService,
    private uiService: UiService,
  ) { }

  initSyncWorker(): void {
    if (this.initialized) {
      console.log('already initialized');
      return;
    }

    console.log('initializing the TodoService Worker for new Tasks');
    this.intervalForAddNewTasksId = setInterval(() => {
      this.tryEmptyCreateNewTodoItemsTasks();
    }, this.SYNC_INTERVAL);
  }

  stopSyncWorker(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.intervalForAddNewTasksId) {
      clearInterval(this.intervalForAddNewTasksId);
    }

  }

  tryEmptyCreateNewTodoItemsTasks(): void {
    this.lastTrySyncTimeStamp.next(new Date());
    if (!this.onlineService.onlineMode.getValue()) {
      console.log('skipping sync todo items as still offline');
      return;
    }

    console.log('running new todo sync items');

    const syncTasks = this.getExistingSyncTasks();
    const requests: Observable<any>[] = [];

    syncTasks.forEach((task: SyncTask<Todo>) => {
      const params = { params: new HttpParams({ fromString: task.params }) };
      const obs$ = this.http.post(task.url, task.body, params)
        .pipe(map(_ => task));

      requests.push(obs$);
    });

    const all$ = concat(...requests).pipe(share());

    const re = all$.subscribe(task => {
      const index = syncTasks.findIndex(t => t == task);
      syncTasks.splice(index, 1);
      console.log(`removed task from ${index}`);
      // this.syncTasks.next(syncTasks);
      localStorage.setItem(FACCIOBENE_TODO_ITEM_TASKS, JSON.stringify(syncTasks));
    });

    this.lastSuccessSyncTimeStamp.next(new Date());
    this.nextSyncTimeStamp.next(new Date(this.lastSuccessSyncTimeStamp.getValue().getTime() + this.SYNC_INTERVAL));

  }

  getFullTodoItemsCachedList(): Todo[] {
    const items = localStorage.getItem(FACCIOBENE_TODO_ITEM_ITEMS);
    return (items)
      ? JSON.parse(items)
      : [];
  }

  public loadCachedTodoItems(): void {
    this.syncedTodoItems.next(this.getFullTodoItemsCachedList());
  }

  syncTodoItems(): Observable<Todo[]> {
    console.log('syncTodoItems');
    // TODO: make sure that we empty the current offline tasks before running sync...
    this.uiService.isLoadingTodos$.next(true);
    return this.http.get<Todo[]>(this.urls.SYNC_ITEMS).pipe(tap((response) => {
      console.log('synced todo items');
      this.syncedTodoItems.next(response);
      this.uiService.isLoadingTodos$.next(false);
      this.saveSyncedTodosToStorage();
    }));
  }

  async addNewTodoItemByTitleV2(todoTitle: string, options: { due_date?: string } = {}): Promise<void> {
    todoTitle = todoTitle.trim();
    if (!todoTitle) { return; }

    let currentList = this.listService.currentList.value;
    let currentListId = this.listService.currentListId.value;

    if (!currentList || !currentListId) {
      currentList = this.listService.getCachedAllList();
      currentListId = currentList.id;
    }

    let todoList = currentList;
    let todoListId = currentList.id;

    if (!!currentList && currentList.type !== ListType.DEFAULT) {
      // if we are not adding to a default list e.g. special one as today,
      // assigned to me or so -> find default list for new users, if none set ->
      // add to list all...
      const default_list_id_for_new_todos = this.userService.userPreference.getValue().default_list_id_for_new_todos;
      if (default_list_id_for_new_todos != null) {
        todoList = this.listService.getCachedListById(default_list_id_for_new_todos);
        todoListId = todoList.id;
        console.log(`adding new todo to list id ${todoListId}`);
      } else {
        todoList = this.listService.getCachedAllList();
        todoListId = todoList.id;
      }
    }

    const todo: Todo = {
      title: todoTitle,
      done: false,
      ui_uuid: uuidv4(),
      list: todoListId,
      // TODO: check if list objects is really needed here
      list_object: todoList
    };

    // Today list
    if (currentList && currentList.type === ListType.TODAY) {
      todo.due_date = getIsoToday();
    }

    // Tomorrow List
    if (currentList && currentList.type === ListType.TOMORROW) {
      todo.due_date = getIsoTomorrow();
    }

    // Assigned to me
    if (currentList && currentList.type === ListType.ASSIGNED_TO_ME) {
      todo.assignee = this.authService.getUserId();
    }

    console.log(options);
    if (options && options.due_date) {
      todo.due_date = options.due_date;
    }

    await this.tryCreateTodoItemOrCreateTask(todo).toPromise();
  }

  getTodosBetweenDates(fromDate, toDate): Observable<Todo[]> {
    console.log('getTodosBetweenDates');
    return this.http.get<Todo[]>(this.urls.MY_TODOS, { params: { from_date: fromDate, to_date: toDate } });
  }

  getTodosBetweenDatesV2(toDateString: string): Observable<Todo[]> {
    console.log('getTodosBetweenDatesV2');

    const toDate = new Date(toDateString);
    console.log(this.syncedTodoItems.getValue());
    const todoListInRange = [];
    this.syncedTodoItems.getValue().forEach(el => {
      if (!!el.due_date && (new Date(el.due_date)) <= toDate) {
        todoListInRange.push(el);
      }
    });
    return of(todoListInRange);
  }

  private getTodoUUID(todoUUID: string): Observable<Todo> {
    console.log('getTodoUUID');
    return this.http.get<Todo>(this.urls.MY_TODOS_BY_UUID(todoUUID));
  }

  getTodoUIUUID(todoUUID: string): Observable<Todo> {
    console.log('getTodoUIUUID');
    return this.http.get<Todo>(this.urls.MY_TODOS_BY_UI_UUID(todoUUID));
  }

  getTodoByUUID(todoUUID: string): Observable<Todo> {
    let result: Todo = null;
    console.log(`getTodoByUUID`);
    for (const item of this.syncedTodoItems.getValue()) {
      if (item.uuid === todoUUID) {
        result = item;
        return of(result);
      }
    }

    console.log('did not find the todo by uuid in the list of syncedTodoItems, fetching...');
    return this.getTodoUUID(todoUUID);
  }

  getTodoByUIUUID(todoUUID: string): Observable<Todo> {
    let result: Todo = null;
    console.log(`getTodoByUIUUID`);
    for (const item of this.syncedTodoItems.getValue()) {
      if (item.ui_uuid === todoUUID) {
        console.log(`found todo by ui uuid`);
        console.log(item);
        result = item;
        return of(result);
      }
    }

    console.log('did not find the todo by ui uuid in the list of syncedTodoItems, fetching...');
    return this.getTodoUIUUID(todoUUID);
  }

  getPossibleAssigneesV2(listId: number): Observable<Assignee[]> {
    return of(this.getPossibleAssigneesForList(listId));
  }

  private getPossibleAssigneesForList(listId: number, collaborators: Assignee[] = []): Assignee[] {
    console.log('getPossibleAssigneesForList');
    const lists = this.listService.getCachedFlatLists();
    console.log(lists);
    console.log(`getPossibleAssigneesForList`);

    let todoList: List = null;
    for (const list of lists) {
      if (list.id === listId) {
        console.log(`found list for assignees: `);
        console.log(list);
        todoList = list;
        break;
      }
    }

    if (!!todoList) {
      // make sure we do not add duplicates
      todoList.collaborators.forEach((element) => {
        if (!collaborators.find(el => el.id === element.id)) {
          collaborators.push(element);
        }
      });

      if (todoList.parent) {
        console.log(`the list ${todoList.title} has a parent list: ${todoList.parent}, looking there too`);
        return this.getPossibleAssigneesForList(todoList.parent, collaborators);
      }

      return collaborators;
    }

    // TODO: think about this later
    console.error('did not found the list in the cached lists');
    return [];
  }

  getCachedTodosByListIdV2(listId: number): Todo[] | null {
    console.log('getCachedTodosByListId: ' + listId);
    let parsedTodos: Todo[] = [];

    let lists = this.listService.getCachedFlatLists();

    lists = lists.filter(el => el.id === (+listId));
    let list: List = null;
    if (lists && lists.length) {
      list = lists[0];
    }

    parsedTodos = getTodosByList(list, this.syncedTodoItems.getValue(), true, this.authService.getUserId());

    // TODO: add more specialties about those other lists
    console.log(`todos ${parsedTodos.length} found in cache for list ${listId}`);
    return parsedTodos;
  }

  addComment(todo: Todo, content): Observable<TodoComment> {
    // TODO: change this to use uuid
    return this.http.post<TodoComment>(this.urls.MY_TODOS_COMMENTS(todo.id), { content })
      .pipe(tap((response) => {
        todo.comments.push(response);
        console.log('updated todo item');
        this.updateCachedTodoOrAdd(todo);
      }));
  }

  removeComment(commentId): Observable<any> {
    return this.http.delete(this.urls.MY_TODOS_COMMENTS_BY_ID(commentId));
  }

  addTodo(todo: Todo): Observable<Todo> {
    return this.http.post<Todo>(this.urls.MY_TODOS, todo);
  }

  private tryCreateTodoItemOrCreateTask(todo: Todo): Observable<Todo> {
    this.updateCachedTodoOrAdd(todo);
    const httpParams = new HttpParams();
    return this.http.post<Todo>(this.urls.MY_TODOS, todo)
      .pipe(
        timeout(HTTP_TIMEOUT_IN_MS),
        retry(2),
        tap((response) => {
          console.log(`response :`);
          console.log(response);
          this.updateCachedTodoOrAdd(response);
        }),
        catchError((err: HttpErrorResponse) => {
          return this.handleTryCreateTodoItemError(err, this.urls.MY_TODOS, todo, httpParams);
        }),
        share()
      );
  }

  private handleTryCreateTodoItemError(
    err: HttpErrorResponse,
    url: string,
    payload: Todo,
    params: HttpParams
  ): Observable<Todo> {
    console.log('handleTryCreateTodoItemError');
    if (this.offlineOrBadConnection(err)) {
      this.addCreateTodoItemTask<Todo>(url, payload, params);

      return EMPTY;
    } else {
      console.log('A backend error occurred.', err);
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      return throwError(err);
    }
  }

  private addCreateTodoItemTask<T>(url: string, payload: Todo, params: HttpParams): void {
    console.log('adding sync task');
    const tasks = this.getExistingSyncTasks();

    const syncTask = new SyncTask(url, payload, params.toString());
    tasks.push(syncTask);
    localStorage.setItem(FACCIOBENE_TODO_ITEM_TASKS, JSON.stringify(tasks));
  }

  public getExistingSyncTasks(): SyncTask<Todo>[] {
    const serializedTasks = localStorage.getItem(FACCIOBENE_TODO_ITEM_TASKS);

    return (serializedTasks)
      ? JSON.parse(serializedTasks)
      : [];
  }

  private offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return (
      (!!err && err instanceof TimeoutError) ||
      (!!err && err.error instanceof ErrorEvent) ||
      !this.onlineService.onlineMode.getValue()
    );
  }

  patchTodo(todo: Todo): Observable<any> {
    // TODO: save this to the synced Todos
    this.updateCachedTodoOrAdd(todo);
    return this.http.patch(this.urls.MY_TODOS_BY_ID(todo.id), todo).pipe(tap((response) => {
      console.log('updated todo item');
      this.updateCachedTodoOrAdd(response);
    }));
  }

  private updateCachedTodoOrAdd(todo: Todo): void {
    console.log(`going to update or add todo`);
    console.log(todo);
    let found = false;
    let todos = this.syncedTodoItems.getValue();

    todos.forEach(element => {
      if (element.uuid === todo.uuid) {
        element = todo;
        found = true;
        console.log(`updated cached todo ${todo.uuid}`);
      }
    });

    if (!found) {
      todos.forEach(element => {
        if (element.ui_uuid === todo.ui_uuid) {
          element = todo;
          found = true;
          console.log(`updated cached todo with ui uuid ${todo.ui_uuid}`);
        }
      });
    }

    if (!found) {
      console.warn('did not found todo in synced todos');
      todos = [todo, ...todos];
    }
    setTimeout(() => {
      console.log('added or updated todo to synced todos');
      console.log(todo);
      console.log(todos[0]);
      this.syncedTodoItems.next(todos);
      this.saveSyncedTodosToStorage();
    }, 50);
  }

  private removeCachedTodo(todo: Todo): void {
    this.syncedTodoItems.next(this.syncedTodoItems.getValue().filter((obj) => obj.uuid !== todo.uuid));
    this.saveSyncedTodosToStorage();
  }

  private saveSyncedTodosToStorage(): void {
    console.log(`saveSyncedTodosToStorage`);
    localStorage.setItem(FACCIOBENE_TODO_ITEM_ITEMS, JSON.stringify(this.syncedTodoItems.getValue()));
  }

  changeCheckTodo(todo: Todo, isChecked: boolean): Observable<any> {
    this.syncedTodoItems.getValue().forEach(el => {
      if (el.id === todo.id) {
        el.done = todo.done;
      }
    });
    if (todo.done) {
      setTimeout(() => {
        console.log('removing todo from synced todos');
        this.syncedTodoItems.next(this.syncedTodoItems.getValue().filter(el => el.uuid !== todo.uuid));
      }, 1000);
    }

    // TODO: update this code to work offline
    // TODO: change this to use uuid
    return this.http.patch(this.urls.CHANGE_CHECK_STATUS_FOR_TODOS_BY_ID(todo.id), { isChecked });
  }

  deleteTodo(todo: Todo): Observable<any> {
    // TODO: change this to use uuid
    const url = this.urls.MY_TODOS_BY_ID(todo.id);
    return this.http.delete(url).pipe(tap((response) => {
      this.removeCachedTodo(todo);
      console.log('removed todo item');
    }));
  }

  public searchTodos(params): Observable<IListResponse<Todo>> {

    const query = params['query'];
    const limit = params['limit'];

    let todos: Todo[] = [];

    if (query !== '') {
      console.log('filtering');
      todos = this.syncedTodoItems.getValue().filter(el => {
        return el.title.toLowerCase().includes(query.toLowerCase());
      });
      console.log(todos.length);
    } else {
      console.log('not filtering');
      todos = this.syncedTodoItems.getValue();
      console.log(todos.length);
    }

    // todos = todos.splice(limit);
    // console.log(todos.length)

    return of({
      next: null,
      previous: null,
      count: todos.length,
      results: todos
    });
  }
}
