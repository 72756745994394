import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { List } from '@facciobene-webapp/app/modules/my-module/modules/board-module/interfaces/list.interface';
import { PublicTodoService } from '@facciobene-webapp/app/services/public-todo.service';


@Component({
  selector: 'app-public-list',
  templateUrl: './public-list.component.html',
  styleUrls: ['./public-list.component.scss'],
})
export class PublicListComponent implements OnInit {
  isLoading = new BehaviorSubject(false);

  todos = [];
  list: List = null;

  listUUID = null;

  constructor(
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private publicTodoService: PublicTodoService
  ) {
    this.isLoading.asObservable().subscribe(val => {
      if (val) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.isLoading.next(true);
      console.log('route.params');
      const listUUID = params.uuid;
      console.log('route.params: ' + listUUID);
      if (listUUID) {
        this.listUUID = listUUID;
        try {
          this.spinnerService.show();
          const response = await this.publicTodoService.getTodosByListUUID(listUUID).toPromise();
          this.todos = response.todos;
          this.list = response.list;
        } catch (error) {
          console.error(error);
        }
        this.spinnerService.hide();
        this.isLoading.next(false);
      } else {
        this.isLoading.next(false);
      }
    });
  }
}
