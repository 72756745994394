import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@facciobene-webapp/app/services/auth.service';
import { getTodosByList } from '@facciobene-webapp/app/utils/list-utils';
import { Todo } from '../../my-module/modules/board-module/interfaces/todo.interface';
import { ListService } from '../../my-module/modules/board-module/services/list.service';

@Pipe({
  name: 'filterList',
  // TODO: remove pure false stuff
  pure: false
})
export class FilterListPipe implements PipeTransform {

  constructor(
    private listService: ListService,
    private authService: AuthService,
  ) {
  }

  transform(items: Todo[]): any {
    if (!items) {
      return items;
    }

    const list = this.listService.currentList.getValue();
    if (list === null) {
      return items;
    }

    // console.log(`filtering todos by list: ${list.id}`);

    return getTodosByList(list, items, true, this.authService.getUserId());

  }
}
