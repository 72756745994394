import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@facciobene-webapp/app/services/auth.service';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  isLoading = true;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.authService.isAuthenticatedUser()) {
        console.log('already authenticated as user, forwarding');
        this.isLoading = false;
        this.router.navigate(['/my/board']);
        return;
      } else {
        this.router.navigate(['/login']);
      }
      this.isLoading = false;
    }, 500);
  }
}
