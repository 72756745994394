import { Inject, Injectable, Optional } from '@angular/core';
import { FACCIOBENE_ENVIRONMENT } from '../injectors.constant';
import { IEnvironment } from '../interfaces/environment.interface';

@Injectable({ providedIn: 'root' })
export class UrlConstantsService {
  get baseUrl(): string {
    return this.environment.baseUrl;
  }

  public HEALTH_STATUS = `${this.baseUrl}/api/v1/health-status`;

  public REFRESH_TOKENS = `${this.baseUrl}/api/v1/authentication/token-refresh/`;
  public USER_LOGIN_EMAIL = `${this.baseUrl}/api/v1/authentication/login`;
  public USER_REGISTER_EMAIL = `${this.baseUrl}/api/v1/authentication/register-user-by-email`;
  public USER_FORGET_PASSWORD = `${this.baseUrl}/api/v1/authentication/password/reset`;
  public USERS_SELF = `${this.baseUrl}/api/v1/authentication/self`;
  public USER_PREFERENCE = `${this.baseUrl}/api/v1/authentication/preferences`;
  public USER_FEEDBACK = `${this.baseUrl}/api/v1/authentication/feedback`;
  public PUBLIC_TODOS_BY_LIST_UUID = (uuid) => `${this.baseUrl}/api/v1/items/public/lists/${uuid}`;

  constructor(@Optional() @Inject(FACCIOBENE_ENVIRONMENT) public environment: IEnvironment) { }

}
