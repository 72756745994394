import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { getIsoToday, getIsoTomorrow } from '@facciobene-webapp/app/lib';
import { TodoDetailsFullViewComponent } from '@facciobene-webapp/app/modules/my-module/modules/board-module/pages/todo-details-full-view/todo-details-full-view.component';
import { UserService } from '@facciobene-webapp/app/services/user.service';
import { replaceURLs } from '@facciobene-webapp/app/shared/utils';
import { Todo } from '../../../my-module/modules/board-module/interfaces/todo.interface';
import { ListService } from '../../../my-module/modules/board-module/services/list.service';
import { TodoService } from '../../../my-module/modules/board-module/services/todo.service';

@Component({
  selector: 'app-todo-item',
  templateUrl: './todo-item.component.html',
  styleUrls: ['./todo-item.component.scss']
})
export class TodoItemComponent implements OnInit {

  @Input() todoItem: Todo;

  @Input() readOnly: false;

  today = getIsoToday();
  tomorrow = getIsoTomorrow();

  constructor(
    private todoService: TodoService,
    private listService: ListService,
    private userService: UserService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.todoItem.formattedHtmlTitle = replaceURLs(this.todoItem.title);
  }

  openDetails(event, todo: Todo): void {
    console.log(event);
    const target = event.target;
    console.log(`target is: ${target.id}`);
    console.log(`nodeName is: ${target.nodeName}`);
    if (target.nodeName === 'A') {
      console.log('opening link: ' + target.href);
      return;
    }
    if (this.readOnly) {
      console.log('this todo is read only');
      return;
    }
    console.log(`opening details for todo uuid: ${todo.uuid}, ui_uuid: ${todo.ui_uuid} from list: ${todo.list}`);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.data = {
      todoUUID: todo.uuid,
      todoUIUUID: todo.ui_uuid
    };
    this.dialog.open(TodoDetailsFullViewComponent, dialogConfig);
  }

  async changeStatus(event: Event, todo: Todo): Promise<void> {
    const isChecked = (<HTMLInputElement>event.target).checked;
    console.log(isChecked);
    todo.done = isChecked;
    todo.disabled = true;

    // TODO: this should be in the todo service
    const p = this.userService.userPreference.getValue();
    if (isChecked && !!p && p.make_sound_when_checked_todo) {
      const audio = new Audio('/assets/zapsplat_bells_small_bell_service_or_elevator_18043.mp3');
      audio.play();
    }

    await this.todoService.changeCheckTodo(todo, isChecked).toPromise();
    this.toastrService.success('done');
  }

  async remove(todo: Todo): Promise<void> {
    if (confirm('Are you sure to delete ' + todo.title)) {
      await this.todoService.deleteTodo(todo).toPromise();
      this.listService.shallRefreshLists.next(true);
    }
  }

  async postPoneBy(item: Todo, byDate: 'today' | 'day' | 'week' | 'month'): Promise<void> {
    console.log(item);
    console.log(byDate);
    let daysToPostPone = 0;
    if (byDate === 'day') {
      daysToPostPone = 1;
    }

    if (byDate === 'week') {
      daysToPostPone = 7;
    }

    if (byDate === 'month') {
      daysToPostPone = 30;
    }

    const oldDueDate = new Date().toISOString().split('T')[0];
    console.log(oldDueDate);

    const newDueDate = new Date(new Date(oldDueDate).getTime() + (daysToPostPone * 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
    console.log(newDueDate);

    item.due_date = newDueDate;
    await this.todoService.patchTodo(item).toPromise();
    this.toastrService.success(`updated due date to ${item.due_date}`);
  }

}
