import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Assignee } from '../interfaces/todo.interface';

@Injectable({ providedIn: 'root' })
export class UiService {

  isLoadingTodos$ = new BehaviorSubject<boolean>(false);
  public filteredAssignee = new BehaviorSubject<Assignee>(null);

  constructor(
    private spinnerService: NgxSpinnerService
  ) {
    this.isLoadingTodos$.subscribe((val) => {
      console.log(`isLoadingTodos: ${val}`);
      // if (val) {
      //   this.spinnerService.show();
      // } else {
      //   this.spinnerService.hide();
      // }
    });
  }
}
