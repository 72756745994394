<ngx-spinner style="color: white">Loading...</ngx-spinner>

<div class="fluid-container main-container pt-2">

    <div class="container pt-4">

        <h1 class="list-title text-center" *ngIf="list">{{list?.title}} by {{list?.owner_object?.name}}</h1>
        <hr>
        <app-todo-item *ngFor="let todo of todos" [todoItem]="todo" [readOnly]="true"></app-todo-item>
        <!-- <pre>{{todos | json}}</pre> -->
    </div>


    <div *ngIf="!isLoading && !listUUID">
        <div class="alert alert-info" role="alert">
            List Not found
        </div>
    </div>

    <div *ngIf="!isLoading && todos.length === 0 ">
        <div class="alert alert-info" role="alert">
            This List has no todos items...
        </div>
    </div>
</div>